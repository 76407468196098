<template>
  <!-- Contact Section Start -->
  <div id="rs-contact" class="rs-contact rs-rain-animate gray-color pb-100">
    <div class="container">
      <div class="row">
        <div class="col-lg-4">
          <div class="contact-box">
            <div class="sec-title mb-45">
              <span class="sub-text new-text white-color">Want To know More?</span>
              <h2 class="title white-color">Talk To Us</h2>
            </div>
            <div class="address-box mb-5">
              <div class="address-icon">
                <i class="fa fa-facebook"></i>
              </div>
              <div class="address-text">
                <span class="label">Talk to us on Facebook</span>
                <a href="https://m.me/nedexdotio">Nedex Group Sdn Bhd (1274853-V)</a><br/>
              </div>
            </div>
            <div class="address-box mb-5">
              <div class="address-icon">
                <i class="fa fa-phone"></i>
              </div>
              <div class="address-text">
                <span class="label">Contact</span>
                <a href="tel:60358860986">+603 5886 0986</a>
              </div>
            </div>
            <div class="address-box">
              <div class="address-icon">
                <i class="fa fa-map-marker"></i>
              </div>
              <div class="address-text">
                <span class="label">Address</span>
                <div class="desc">1-1, USJ21/5, 47630 Subang Jaya</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-8 pl-70 md-pl-15 md-mt-40">
          <div class="contact-widget onepage-style">
            <div class="sec-title2 mb-45">
              <span class="sub-text gold-color">Contact Us</span>
              <h2 class="title title7">
                <!--Need a customization service? Drop us a message now!-->
                Drop us a message to get a free consultation today!
              </h2>
            </div>
            <div class="success-messages pb-20 text-success" v-show="form_success">Thank you! We will contact you shortly!</div>
            <div class="fail-messages pb-20 text-danger" v-show="form_fail">We are sorry, there is an error with the form submission, please try again!</div>
            <form id="contact-us" name="contact-us" action="/contact-us/success" method="post" data-netlify="true" data-netlify-honeypot="bot-field" @submit.prevent="onSubmit">
              <input type="hidden" name="form-name" value="contact-us">
              <fieldset>
                <div class="row">
                  <div class="col-lg-6 mb-30 col-md-6 col-sm-6">
                    <input class="form-control" type="text" id="name" name="name" v-model="form.name" placeholder="Name" required="">
                  </div>
                  <div class="col-lg-6 mb-30 col-md-6 col-sm-6">
                    <input class="form-control" type="number" id="phone" name="phone" v-model="form.phone" placeholder="Phone Number" required="">
                  </div>
                  <div class="col-lg-12 mb-30 col-md-6 col-sm-6">
                    <input class="form-control" type="email" id="email" name="email" v-model="form.email" placeholder="E-Mail" required="">
                  </div>
                  <div class="col-lg-12 mb-30">
                    <textarea class="form-control" id="message" name="message" v-model="form.message" placeholder="Leave your message/enquiries here" required=""></textarea>
                  </div>
                </div>
                <div class="btn-part">
                  <div class="form-group mb-0">
                    <button class="readon learn-more submit" type="submit" @click.prevent="onSubmit">Submit</button>
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="line-inner style2">
      <div class="line"></div>
      <div class="line"></div>
      <div class="line"></div>
    </div>
  </div>
  <!-- Contact Section Start -->
</template>

<script>
  import axios from 'axios';
  import ContactUsSuccess from "./ContactUsSuccess";
export default {
  name: "ContactForm",
  data () {
    return {
      form: {
        name: '',
        phone: '',
        email: '',
        message: ''
      },
      form_success: false,
      form_fail: false
    }
  },
  methods:{
      encode (data) {
        return Object.keys(data)
                .map(
                     key=> `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
                )
                .join("&");
      },
    checkForm() {
        if(this.form.name === '') {
          return false
        }else if(this.form.email === '') {
          return false
        }else if(this.form.phone === '') {
          return false
        }else if(this.form.message === ''){
          return false
        }else {
          return true
        }
    },
    onSubmit(){
        if(this.checkForm()){
           const axiosConfig = {
              header: { "ContentType": "application/x-www-form-urlencoded"}
          };

           axios.post(
                   "/",
                   this.encode({
                     "form-name": "contact-us",
                     ...this.form
                   }),
                   axiosConfig
           ).then(()=>{
             this.form_success = true
           }).catch(()=>{
              this.form_fail = true
           });
        }
    }
  }
}
</script>
